import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function StayWithYou() {
  return (
    <Layout>
      <Helmet title="Stay With You | John Legend" />

      <h1>Stay With You</h1>

      <h2>John Legend</h2>

      <h4>Key: A</h4>

      <h4>Tempo: 74</h4>

      <p>Non-ASCAP</p>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            We've been <Ch>A/C#</Ch>together for a <Ch>Bm/E</Ch>while <Ch>A</Ch>
            now
          </p>
          <p>
            We're growing <Ch>A/C#</Ch>stronger every<Ch>Bm/E</Ch>day <Ch>A</Ch>
            now
          </p>
          <p>
            It feels so <Ch>A/C#</Ch>good and there's <Ch>Bm/E</Ch>no <Ch>A</Ch>
            doubt
          </p>
          <p>
            I will <Ch>A/C#</Ch>stay <Ch>Bm/G</Ch>with <Ch>F#m7</Ch>you
          </p>
        </Verse>

        <Verse>
          <p>
            as each <Ch>A/C#</Ch>morning brings <Ch>Bm/E</Ch>sun<Ch>A</Ch>rise
          </p>
          <p>
            And the <Ch>A/C#</Ch>flowers bloom in <Ch>Bm/E</Ch>spring<Ch>A</Ch>
            time
          </p>
          <p>
            All my <Ch>A/C#</Ch>love you <Ch>Bm/E</Ch>can <Ch>A</Ch>rely
          </p>
          <p>
            And I'll <Ch>A/C#</Ch>stay <Ch>Bm/E</Ch>with <Ch>F#m</Ch>you
          </p>
        </Verse>

        <Chorus>
          <p>
            Oh I'll <Ch>D</Ch>stay with <Ch>C#m</Ch>you through the <Ch>Bm</Ch>
            ups and the <Ch>A</Ch>downs
          </p>
          <p>
            Oh I'll <Ch>D</Ch>stay with <Ch>C#m</Ch>you when no one <Ch>Bm</Ch>
            else is <Ch>A</Ch>around
          </p>
          <p>
            And when the <Ch>F#m7</Ch>dark clouds <Ch>Bm7</Ch>arrive
          </p>
          <p>
            I will <Ch>E</Ch>stay by your <Ch>A</Ch>side
          </p>
          <p>
            I know <Ch>F#m7</Ch>we'll be al<Ch>Bm7</Ch>right
          </p>
          <p>
            I will <Ch>E</Ch>stay with <Ch>A</Ch>you
          </p>
        </Chorus>

        <Verse>
          <p>
            Though rela<Ch>A/C#</Ch>tionships can <Ch>Bm/E</Ch>get <Ch>A</Ch>old
          </p>
          <p>
            They had a <Ch>A/C#</Ch>tendency to <Ch>Bm/E</Ch>grow <Ch>A</Ch>cold
          </p>
          <p>
            We have <Ch>A/C#</Ch>something like <Ch>Bm/E</Ch>mira<Ch>A</Ch>cle
          </p>
          <p>
            Yeah, I'll <Ch>A/C#</Ch>stay <Ch>Bm/E</Ch>with <Ch>F#m7</Ch>you
          </p>
        </Verse>

        <Chorus>
          <p>
            Oh I'll <Ch>D</Ch>stay with <Ch>C#m</Ch>you through the <Ch>Bm</Ch>
            ups and the <Ch>A</Ch>downs
          </p>
          <p>
            Oh I'll <Ch>D</Ch>stay with <Ch>C#m</Ch>you when no one <Ch>Bm</Ch>
            else is <Ch>A</Ch>around
          </p>
          <p>
            And when the <Ch>F#m7</Ch>dark clouds <Ch>Bm7</Ch>arrive
          </p>
          <p>
            I will <Ch>E</Ch>stay by your <Ch>A</Ch>side
          </p>
          <p>
            I know <Ch>F#m7</Ch>we'll be al<Ch>Bm7</Ch>right
          </p>
          <p>
            I will <Ch>E</Ch>stay with <Ch>A</Ch>you
          </p>
        </Chorus>

        <Bridge>
          <p>
            <Ch>E</Ch>And there will be <Ch>D</Ch>heartaches and <Ch>A</Ch>
            pains, yes it will
          </p>
          <p>
            <Ch>E</Ch>But through it <Ch>D</Ch>all, we will re<Ch>A</Ch>main
          </p>
          <p>
            <Ch>E</Ch>In this life, we <Ch>D</Ch>all know
          </p>
          <p>
            Friends may <Ch>C#m</Ch>come, and <Ch>F#m7</Ch>they may go
          </p>
          <p>
            But <Ch>Bm7</Ch>through the years I know
          </p>
          <p>
            <Ch>E</Ch>I will stay
          </p>
        </Bridge>

        <Verse>
          <p>
            And in the <Ch>A/C#</Ch>end I know that <Ch>Bm/E</Ch>we'll{" "}
            <Ch>A</Ch>find
          </p>
          <p>
            Love so <Ch>A/C#</Ch>beautiful <Ch>Bm/E</Ch>and di<Ch>A</Ch>vine
          </p>
          <p>
            We'll be <Ch>A/C#</Ch>lovers for the <Ch>Bm/E</Ch>lifetime<Ch>A</Ch>
            , yeah
          </p>
          <p>
            And I'll <Ch>A/C#</Ch>stay <Ch>Bm/E</Ch>with <Ch>F#m7</Ch>you
          </p>
        </Verse>

        <Chorus>
          <p>
            Oh I'll <Ch>D</Ch>stay with <Ch>C#m</Ch>you through the <Ch>Bm</Ch>
            ups and the <Ch>A</Ch>downs
          </p>
          <p>
            Oh I'll <Ch>D</Ch>stay with <Ch>C#m</Ch>you when no one <Ch>Bm</Ch>
            else is <Ch>A</Ch>around
          </p>
          <p>
            And when the <Ch>F#m7</Ch>dark clouds <Ch>Bm7</Ch>arrive
          </p>
          <p>
            I will <Ch>E</Ch>stay by your <Ch>A</Ch>side
          </p>
          <p>
            I know <Ch>F#m7</Ch>we'll be al<Ch>Bm7</Ch>right
          </p>
          <p>
            I will <Ch>E</Ch>stay with <Ch>A</Ch>you
          </p>
        </Chorus>

        <PreChorus>
          <p>
            Every<Ch>F#m7</Ch>thing will be <Ch>Bm7</Ch>fine
          </p>
          <p>
            And I will <Ch>E</Ch>stay with <Ch>A</Ch>you
          </p>
          <p>
            Through the <Ch>F#m7</Ch>end of <Ch>Bm7</Ch>time
          </p>
          <p>
            I will <Ch>E</Ch>stay with <Ch>A</Ch>you
          </p>
        </PreChorus>
      </Columns>
    </Layout>
  );
}
